//Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from '@material-ui/core';

// core components
import componentStyles from "assets/theme/components/loading.js";

const useStyles = makeStyles(componentStyles);

const Message = ({ text }) =>{

    const classes = useStyles();

    return(
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100vw" height="100vh">
            <Typography variant="h2" classes={{ root: classes.typographyRootH2 }}>{ text }</Typography>
        </Box>
    );
};

export default Message