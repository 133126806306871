import {useEffect} from "react";
import { useLocation, useHistory, Route, Switch, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Box, Container} from "@material-ui/core/";
// @material-ui/icons components

// core components
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import NavbarDropdown from "components/Dropdowns/NavbarDropdown.js";

import routes from "routes.js";

import componentStyles from "assets/theme/layouts/admin.js";

const useStyles = makeStyles(componentStyles);

const Admin = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.layout + prop.path} component={prop.component} key={key}/>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <>
        <Sidebar routes={routes} logo={{innerLink: "/admin/index", imgSrc: require("../assets/img/brand/birdseye.png").default, imgAlt: "Birdseye Church logo",}} dropdown={<NavbarDropdown history={history}/>}/>
        <Box position="relative" className={classes.mainContent}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/index" />
          </Switch>
          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
            <AdminFooter/>
          </Container>
        </Box>
      </>
    </>
  );
};

export default Admin;
