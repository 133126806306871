import { Button, Dialog, Box, Typography, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { useState } from 'react';

const GoogleSheetUrlDialog = ({open, onClick, handleLinkChange}) => {

    const [validGSheetURL, setValidGSheetURL] = useState(false);    

    const handleLinkLocalChange = (e) => { 
      const regex = /([http]*[s]*:\/\/docs.google.com\/spreadsheets\/d\/)([a-zA-Z0-9[_-]+)([/a-z#=0-9]*)/g;
      const match= e.target.value.match(regex);
      if(match) {
        handleLinkChange(e);
        setValidGSheetURL(true);
      } else { 
        setValidGSheetURL(false);
      }
    }
    return (
        <Dialog open={open}>
            <DialogTitle><Box component={Typography} variant="h2">Spreadsheet link</Box></DialogTitle>
            <DialogContent>
                <DialogContentText>To enable this service, please enter your spreadsheet link here.</DialogContentText>
                <TextField autoFocus margin="dense" label="Spreadsheet link" type="url" fullWidth variant="standard" onChange={handleLinkLocalChange}
                error={!validGSheetURL} helperText={validGSheetURL ? "" : "Please input a valid Google Sheet URL"}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClick} disabled={!validGSheetURL} variant="contained">SEND</Button>
            </DialogActions>
        </Dialog>
    );
}

export default GoogleSheetUrlDialog;