//React
import { useState } from 'react';
// @material-ui/core components
import { Box, Button, FormControl, FilledInput, FormGroup, FormLabel, Grid, Paper, IconButton, Collapse } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ShareButton = ({classes, buttonFooter, buttonLabel, disabled, imageSrc, label, onShareClick, onRemoveClick, source, shared, comingSoon}) => {
    
    const [CollapseState, setCollapseState] = useState(false);

    const handleCollapseClick = () => setCollapseState(!CollapseState);

    if( comingSoon ){
        return(
            <FormGroup>
                <Button variant="contained" classes={{ root: classes.buttonRoot }} disabled>
                    <Box component="span" marginRight="4px">
                        <Box alt={label+" Logo"} component="img" width="20px" className={classes.buttonImg} src={imageSrc}/>
                    </Box>
                    <Box component="span" marginLeft=".75rem">{buttonLabel} Coming Soon</Box>
                </Button>
            </FormGroup>
        );
    };

    if( !shared || disabled ){
        return(
            <FormGroup>
                <Button variant="contained" classes={{ root: classes.buttonRoot }} disabled={disabled} onClick={onShareClick}>
                    <Box component="span" marginRight="4px">
                        <Box alt={label+" Logo"} component="img" width="20px" className={classes.buttonImg} src={imageSrc}/>
                    </Box>
                    <Box component="span" marginLeft=".75rem">Share {buttonLabel} data</Box>
                </Button>
                {disabled ? null : buttonFooter}
            </FormGroup>
        );
    };

    return(
        <FormGroup>
            <Grid container component={Paper} spacing={1} style={{backgroundColor:'#f8f9fe', padding:'.5rem'}} >
                <Grid item xs={12} container alignItems='center'>
                    <Grid item xs={10} sm={11} container spacing={1}>
                        <Grid item xs={1}>
                            <Box alt={label+" Logo"} component="img" width="20px" className={classes.buttonImg} src={imageSrc}/>
                        </Grid>
                        <Grid item xs={11}>
                            <FormLabel onClick={handleCollapseClick}>{`${label} Connected`}</FormLabel>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <IconButton onClick={handleCollapseClick}>
                            {CollapseState ? 
                                <ExpandLessIcon style={{width:'.8rem', height:'.8rem'}}/> :
                                <ExpandMoreIcon style={{width:'.8rem', height:'.8rem'}}/>
                            }
                        </IconButton>
                    </Grid>
                </Grid>
                <Collapse in={CollapseState}>
                    <Grid container style={{padding:'.5rem'}}>
                        <Grid item xs={12} md={6}>
                            <FormLabel>Shared since</FormLabel>
                            <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled>
                                <Box paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={source.sharedSince}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormLabel>Last Import</FormLabel>
                            <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled>
                                <Box paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={source.lastImport}/>
                            </FormControl>
                        </Grid>
                        <Grid item  xs={12} md={6}>
                            <FormLabel>Total successful imports</FormLabel>
                            <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled>
                                <Box paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={source.successfulImports}/>
                            </FormControl>
                        </Grid>
                        <Grid item  xs={12} md={6}>
                            <FormLabel>Status</FormLabel>
                            <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled>
                                <Box paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={source.status}/>
                            </FormControl>
                        </Grid>
                        <Grid item  xs={12} md={6}>
                            <FormLabel>Total records</FormLabel>
                            <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled>
                                <Box paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={source.totalRecords}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <Button variant="contained" classes={{ root: classes.removeButtonRoot }} onClick={onRemoveClick}>
                                <Box component="span">Revoke access</Box>
                            </Button>
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </FormGroup>
    );
}

export default ShareButton;