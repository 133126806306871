import axios from "axios";

const paymentsEndpoint = "payments";

export const getSubscriptionPrice = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/${paymentsEndpoint}/subscription-price`);    
};

export const cancelSubscription = (subscriptionId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/${paymentsEndpoint}/cancel-subscription?subscription_id=${subscriptionId}`);
};

export const subscribe = (email) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/${paymentsEndpoint}/create-checkout-session`,
    {email: email,}
  );
};