import { useEffect } from 'react';

const useGoogle = () => {

    useEffect(() => {

        const SCOPE = 'https://www.googleapis.com/auth/spreadsheets.readonly';
        const handleClientLoad = () => window.gapi.load('client:auth2', initClient)
    
        const initClient = () => {
            const discoveryUrl = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';
            window.gapi.client.init({
                'clientId': process.env.REACT_APP_GS_CLIENT_ID,
                'discoveryDocs': [discoveryUrl],
                'scope': SCOPE
            });
            console.log("Google loaded");
        };

        const script = document.createElement('script');

        script.src = "https://apis.google.com/js/api.js";
        script.async = true;
        script.defer = true;
        script.onload = handleClientLoad;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };

    }, []);
};

export default useGoogle;