//React
import {useState} from "react";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Box, Button, Card, CardContent, FilledInput, FormControl, Grid, InputAdornment, Snackbar} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";

//Routing
import { Link, useHistory } from 'react-router-dom';

// core components
import componentStyles from "assets/theme/views/auth/login.js";

//Firebase
import { emailLogin, logOut, setSessionPersistence } from "assets/plugins/firebase/auth";

const useStyles = makeStyles(componentStyles);

const Login = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [EmailState, setEmailState] = useState("");
  const [PasswordState, setPasswordState] = useState("");
  const [BadLoginState, setBadLoginState] = useState(false);
  const [ErrorMessageState, setErrorMessageState] = useState(false);
  const [LoadingState, setLoadingState] = useState(false);

  const handleLoginClick = async () => {
    setLoadingState(true);
    try {
      await setSessionPersistence();
      const userCredential = await emailLogin(EmailState, PasswordState);
      if (!userCredential.user.emailVerified) {    
        try {
          await userCredential.user.sendEmailVerification({
            url: `${window.location.protocol}//${window.location.host}`
          });
          history.push('/auth/registersuccessful');
        } catch (error) {
          setBadLoginState(true);
          setLoadingState(false);
          setErrorMessageState('Something went wrong, please try again in a few minutes');
        } finally{
          logOut();
        }
      } else {
        window.location.assign('/admin/index');
      };
    } catch (error) {
      setBadLoginState(true);
      setLoadingState(false);
      setErrorMessageState(error.message);
    };
  };

  const handleEmailChange = event =>{
    setEmailState(event.target.value);
  };

  const handlePasswordChange = event =>{
      setPasswordState(event.target.value);
  };

  const handleKeyPress = event =>{
    if(event.keyCode === 13){
      handleLoginClick(event);
    };
  }

  return (
    <>
      <Grid item xs={12} lg={5} md={7}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardContent classes={{ root: classes.cardContent }}>
            <Box color={theme.palette.gray[600]} textAlign="center" marginBottom="1rem" marginTop=".5rem" fontSize="1rem">
              <Box fontSize="80%" fontWeight="400" component="small">Log in to your account</Box>
            </Box>
            <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important">
              <FilledInput value={EmailState}  onChange={handleEmailChange} onKeyDown={handleKeyPress} autoComplete="off" type="email" placeholder="Email" startAdornment={<InputAdornment position="start"><Email /></InputAdornment>}/>
            </FormControl>
            <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important">
              <FilledInput value={PasswordState}  onChange={handlePasswordChange} onKeyDown={handleKeyPress} autoComplete="off" type="password" placeholder="Password" startAdornment={<InputAdornment position="start"><Lock /></InputAdornment>}/>
            </FormControl>
            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
              <Button variant="contained" onClick={handleLoginClick} disabled={LoadingState}>Sign in</Button>
            </Box>
          </CardContent>
        </Card>
        <Grid container component={Box} marginTop="1rem">
          <Grid item xs={6} component={Box} textAlign="left">
            <Link to="/auth/passwordrecovery" className={classes.footerLinks}>Forgot password</Link>
          </Grid>
          <Grid item xs={6} component={Box} textAlign="right">
            <Link to="/auth/register" className={classes.footerLinks}>Create new account</Link>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={BadLoginState} autoHideDuration={10000} onClose={()=>setBadLoginState(false)}>
        <Alert onClose={()=>setBadLoginState(false)} severity="error">
          {ErrorMessageState}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Login;
