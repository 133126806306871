//React
import { useEffect, useState, createContext } from "react";

//Firebase
import {authState} from "assets/plugins/firebase/auth";

//Loading
import Loading from "components/Loading/Loading";

//Export Context
export const UserContext = createContext();

//Export Provider
export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {      
        authState(user => {
            if(!user){
                setCurrentUser(null);
            }
            else{
                setCurrentUser({
                    user: user, 
                    photo: user.photoURL
                });
            }
            setPending(false);
        });
    }, []);

    if (pending) {
        return <Loading/>
    }

    return (
        <UserContext.Provider value={{currentUser}}>
            {children}
        </UserContext.Provider>
    );
};
