import axios from "axios";
// React
import { useState, useContext, useEffect } from 'react';

// Firestore
import { getUser, updateUserBilling, updateUserChurch, updateUserFacebookSource, updateUserPlanningCenterPeopleSource, updateUserPlanningCenterGivingSource } from 'assets/plugins/firebase/db';

// User Context
import { UserContext } from 'assets/plugins/contexts/userContext';

// Data
import { states } from 'assets/data/states';

// Facebook
import { facebookLogin, facebookAPIAuth, facebookFirebaseUpdate } from 'assets/plugins/facebook';

// Google Sheet
import { googleSheetLogin, googleSheetAPIAuth, googleSheetFirebaseUpdate } from 'assets/plugins/gsheet';

// Google Analytics
import { googleAnalyticsLogin, googleAnalyticsAPIAuth, googleAnalyticsFirebaseUpdate } from 'assets/plugins/ganalytics';

// Youtube
import { youtubeLogin, youtubeAPIAuth, youtubeFirebaseUpdate } from 'assets/plugins/youtube';

// Stripe
import { getSubscriptionPrice, cancelSubscription, subscribe as stripeSubscription } from 'assets/plugins/stripe';

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import { Box, Button, Card, CardContent, CardHeader, Checkbox, Container, Divider, FilledInput, Select as SelectInput, MenuItem, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Typography, IconButton, Snackbar }from "@material-ui/core";

// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit';

// core components
import UserHeader from "components/Headers/UserHeader.js";
import ShareButton from 'components/ShareButton/ShareButton';
import GoogleSheetUrlDialog from 'components/GoogleSheetUrlDialog/GoogleSheetUrlDialog';
import GoogleSheetDialog from 'components/GoogleSheetDialog/GoogleSheetDialog';
import Loading from 'components/Loading/Loading';

import componentStyles from "assets/theme/views/admin/profile.js";
import useFacebook from 'assets/plugins/hooks/useFacebook';
import useGoogle from 'assets/plugins/hooks/useGoogle';

const useStyles = makeStyles(componentStyles);

const Account = () => {
  const classes = useStyles();
  const theme = useTheme();

  const today = new Date();
  const todayF = `${('0'+(today.getMonth() + 1)).slice(-2)}/${('0'+(today.getDate())).slice(-2)}/${today.getFullYear()}`;
  
  // Load external APIs
  useFacebook();
  useGoogle();

  // Contexts
  const {currentUser} = useContext(UserContext);

  // States
  const [LoadingState, setLoadingState] = useState(false);
  const [ErrorState, setErrorState] = useState({error:false, message:""});

  // Editable forms states
  const [AccountEditState, setAccountEditState] = useState(false);

  // Church form fields states
  const [ChurchNameState, setChurchNameState] = useState('');
  const [CongregationState, setCongregationState] = useState('Less than 100');
  const [StateState, setStateState] = useState('AL');
  const [CityState, setCityState] = useState('');
  const [ZipState, setZipState] = useState('');

  // Billing form fields states
  const [SubscriptionPrice, setSubscriptionPrice] = useState('0');

  // Source form fields states
  // Buttons
  const defaultSource = {shared:false, sharedSince:null};
  const [FacebookState, setFacebookState] = useState(defaultSource);
  const [InstagramState, setInstagramState] = useState(defaultSource);
  const [YoutubeState, setYoutubeState] = useState(defaultSource);
  const [GoogleAnalyticsState, setGoogleAnalyticsState] = useState(defaultSource);
  const [PlanningCenterPeopleState, setPlanningCenterPeopleState] = useState(defaultSource);
  const [PlanningCenterGivingState, setPlanningCenterGivingState] = useState(defaultSource);
  const [GoogleSheetsState, setGoogleSheetsState] = useState(defaultSource);
  const [PlanningCenterGivingCheckboxState, setPlanningCenterGivingCheckboxState] = useState(false);
  //Fields
  const [GoogleSheetsLinkState, setGoogleSheetsLinkState] = useState('');

  // Dialogs
  const [GoogleSheetUrlDialogState, setGoogleSheetUrlDialogState] = useState(false);
  const [GoogleSheetDialogState, setGoogleSheetDialogState] = useState(false);

  // Billing
  const [BillingState, setBillingState] = useState({});

  const triggerDAG = dagName => {
      return axios.post(process.env.REACT_APP_AIRFLOW_TRIGGER,
      {
        "dag_name": dagName,
        "account_id": currentUser.user.uid
      });
  };

  // Fetch User
  useEffect(()=>{
    const fetchUser = async () =>{
      try {
        setLoadingState(true);
        const response = await getUser(currentUser.user);
        const user = response.data();
        // Set church form fields states
        setChurchNameState(user.church.name);
        if(user.church.congregationSize !== ''){
          setCongregationState(user.church.congregationSize);
        }
        if(user.church.location.state !== ''){
          setStateState(user.church.location.state);
        }
        setCityState(user.church.location.city);
        setZipState(user.church.location.zip);

          // Set billing form fields states
        setBillingState(user.billing);
          // Set sources form fields states
        setFacebookState(user.sources.facebook);
        setInstagramState(user.sources.instagram);
        setYoutubeState(user.sources.youtube);
        setGoogleAnalyticsState(user.sources.googleAnalytics);
        setPlanningCenterPeopleState(user.sources.planningCenterPeople);
        setPlanningCenterGivingState(user.sources.planningCenterGiving);
        setGoogleSheetsState(user.sources.googleSheets);

        setLoadingState(false);
      } catch (error) {
        setLoadingState(false);
        setErrorState({message:"Fetch user error", error:true});
      }
    };

    fetchUser();
  },[currentUser.user]);

  useEffect(()=> {
    const fecthSubscriptionPrice = async () =>{
      try {
        const { data } = await getSubscriptionPrice();
        setSubscriptionPrice(data.price/100 + '');
      } catch (error) {
        setErrorState({message:"Server error", error:true});
        setSubscriptionPrice('0');
      };
    };

    fecthSubscriptionPrice();
  },[]);

  // What happens when user click edit buttons

  const handleAccountEditClick = () =>{
    setAccountEditState(true);
  };

  const handlePlanningCenterPeopleClick = () =>{
    window.location = `${process.env.REACT_APP_PC_AUTH_URI}${process.env.REACT_APP_API_URL}${process.env.REACT_APP_PC_REDIRECT_URI}${(PlanningCenterGivingCheckboxState ? 'people giving' : 'people')}` ;
  };

  const handlePlanningCenterGivingClick = () =>{
    window.location = `${process.env.REACT_APP_PC_AUTH_URI}${process.env.REACT_APP_API_URL}${process.env.REACT_APP_PC_REDIRECT_URI}${'people giving'}`;
  };

  const handlePlanningCenterPeopleRemoveClick = async () => {
    const data = {
      shared:false,
      uid: currentUser.user.uid,
      planningCenter: {
          sharedSince: null,
          scope: "",
          secretId: null
      }
    };
    try {
      await updateUserPlanningCenterPeopleSource(data);
      await updateUserPlanningCenterGivingSource(data);
      setLoadingState(false);
      setPlanningCenterPeopleState({shared:false, sharedSince: null});
      setPlanningCenterGivingState({shared:false, sharedSince: null});
    } catch (error) {
      setLoadingState(false);
      setErrorState({message:"Firebase error", error:true});
    };
  };

  const handlePlanningCenterGivingRemoveClick = async () =>{
    const data = {
      shared:false,
      uid: currentUser.user.uid,
      planningCenter: {
          sharedSince: null,
          scope: "",
          secretId: null
      }
    };
    try {
      await updateUserPlanningCenterGivingSource(data);
      setLoadingState(false);
      setPlanningCenterGivingState({shared:false, sharedSince: null});
    } catch (error) {
      setLoadingState(false);
      setErrorState({message:"Firebase error", error:true});
    };
  };

  const handleFacebookClick = async () => {
    try {
      const facebookResponse = await facebookLogin();
      setLoadingState(true);
      if(facebookResponse) {
        const { data } = await facebookAPIAuth(facebookResponse);
        if (data.message !== "An error ocurred") {
          const facebookData = {
            secretId: data.secretId,
            shared: true,
          };
          await facebookFirebaseUpdate(currentUser.user, facebookData, todayF);
          await triggerDAG("facebook_pages");
          setLoadingState(false);
          setFacebookState({ shared: true, sharedSince: todayF });
        } else {
          setLoadingState(false);
          setErrorState({message:"There is no Page access associated with the provided Facebook credentials. Please try providing credentials to a Facebook account that does have access the the desired Pages account.", error:true});
        };
      };
    } catch (error) {
      setLoadingState(false);
      setErrorState({message:"Fetch error", error:true});
    };
  };

  const handleFacebookRemoveClick = async () => {
    const data = {
      shared: false,
      uid: currentUser.user.uid,
      secretId: null,
      sharedSince: null
    };
    try {
      await updateUserFacebookSource(data);
      setLoadingState(false);
      setFacebookState({shared:false, sharedSince: null});
    } catch (error) {
      setLoadingState(false);
      setErrorState({message:"Firebase error", error:true});
    };
  };

  const handleGoogleSheetClick = () => {
    setGoogleSheetDialogState(true);
  };

  const handleGoogleSheetContinueClick = async () => {
    setGoogleSheetDialogState(false);
    setLoadingState(true);
    try {
      const googleResponse = await googleSheetLogin();
      const { data } = await googleSheetAPIAuth(googleResponse);
      await triggerDAG("google_sheets");
      setGoogleSheetUrlDialogState(true);
      setLoadingState(false);
      setGoogleSheetsState(data);
    } catch (error) {
      setLoadingState(false);
      setErrorState({message:"Google Sheet error", error: true});
    };
  };

  const handleGoogleSheetLinkClick = async () => {
    setLoadingState(true);
    try {
      const data = {
        link: GoogleSheetsLinkState,
        secretId: GoogleSheetsState.secretId,
        shared: true
      };
      await googleSheetFirebaseUpdate(currentUser.user, data);
      setGoogleSheetUrlDialogState(false);
      setLoadingState(false);
      setGoogleSheetsState({shared:true, sharedSince:todayF});
    } catch (error) {
      setLoadingState(false);
      setErrorState({message:"Firebase error", error: true});
    };
  };

  const handleGoogleSheetRemoveClick = async () =>{
    setLoadingState(true);
    await googleSheetFirebaseUpdate(currentUser.user, {shared:false})
    setLoadingState(false);
    setGoogleSheetsState({shared:false, sharedSince:null});
  };

  const handleGoogleAnalyticsClick = async () => {
    setLoadingState(true);
    try {
      const googleAnalyticsResponse = await googleAnalyticsLogin();
      const { data } = await googleAnalyticsAPIAuth(googleAnalyticsResponse);
      const googleAnalyticsData = {
        secretId: data.secretId,
        shared: true
      };
      await googleAnalyticsFirebaseUpdate(currentUser.user, googleAnalyticsData);
      setGoogleAnalyticsState({shared:true, sharedSince:todayF});
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      setErrorState({message:"Fetch error", error:true});
    };
  };

  const handleGoogleAnalyticsRemoveClick = async () =>{
    setLoadingState(true);
    try {
      await googleAnalyticsFirebaseUpdate(currentUser.user, {shared:false});
      setLoadingState(false);
      setGoogleAnalyticsState({shared:false, sharedSince:null});
    } catch (error) {
      setLoadingState(false);
      setErrorState({message:"Fetch error", error:true});
    };
  };

  const handleYoutubeClick = async () =>{
    setLoadingState(true);
    try {
      const youtubeResponse = await youtubeLogin();
      const { data } = await youtubeAPIAuth(youtubeResponse);
      const youtubeData = {
        secretId: data.secretId,
        shared: true
      };
      await youtubeFirebaseUpdate(currentUser.user, youtubeData);
      setYoutubeState({shared:true, sharedSince:todayF});
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      setErrorState({message:"Fetch error", error:true});
    };
  };

  const handleYoutubeRemoveClick = async () =>{
    setLoadingState(true);
    try {
      await youtubeFirebaseUpdate(currentUser.user, {shared:false});
      setLoadingState(false);
      setYoutubeState({shared:false, sharedSince:null});
    } catch (error) {
      setLoadingState(false);
      setErrorState({message:"Firebase error", error:true});
    };
  };

  // What happens when user click save buttons

  const handleAccountSaveClick = async () =>{
    setLoadingState(true);
    try {
      const data = {
        uid: currentUser.user.uid,
        church:{
          congregationSize: CongregationState,
          location:{
              city: CityState,
              state: StateState,
              zip: ZipState
          },
          name: ChurchNameState
        }
      };
      await updateUserChurch(data);
      setAccountEditState(false);
      setLoadingState(false);
    } catch (error) {
      setAccountEditState(false);
      setLoadingState(false);
      setErrorState({message:"Firebase error", error:true});
    };
  };

  const handleSubscribeClick = async event => {
    event.preventDefault();
    try {
      setLoadingState(true);
      const { data } = await stripeSubscription(currentUser.user.email);
      window.location.href = data.url;
      setLoadingState(false);
    } catch (error) {
      setErrorState({message:"Payment error", error:true});
    };
  };

  const handleCancelSubscriptionClick = async () => {
    setLoadingState(true);
    try {
      await cancelSubscription(BillingState.subscriptionId);
      const newBillingState = {
        uid: currentUser.user.uid,
        subscribed: false
      };
      await updateUserBilling(newBillingState);
      setBillingState(newBillingState);
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
    };
  }

  // Input handlers

    // Church inputs
  const handleChurchNameChange = e =>{
    setChurchNameState(e.target.value);
  }

  const handleCongregationSizeChange = e =>{
    setCongregationState(e.target.value);
  }

  const handleStateChange = e =>{
    setStateState(e.target.value);
  }

  const handleCityChange = e =>{
    setCityState(e.target.value);
  }

  const handleZipChange = e =>{
    setZipState(e.target.value);
  };


    // Sources inputs
  const handlePlanningCenterGivingCheckbox = () =>{
    setPlanningCenterGivingCheckboxState(!PlanningCenterGivingCheckboxState);
  };

  const handleGoogleSheetsLinkChange = e =>{
    setGoogleSheetsLinkState(e.target.value);
  };

  if(LoadingState){return <Loading/>}

  return (
    <>
      <UserHeader title="Account Manager" subTitle="" />
      {/* Page content */}
      <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} component={Box} marginBottom="3rem" classes={{ root: classes.gridItemRoot + " " + classes.order2 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} component={Box} marginBottom="3rem" classes={{ root: classes.gridItemRoot + " " + classes.order2 }}>
                <Card classes={{root: classes.cardRoot + " " + classes.cardRootSecondary,}}>
                  <CardHeader classes={{ root: classes.cardHeaderRoot }}
                    subheader={
                      <Grid container component={Box} alignItems="center" justifyContent="space-between">
                        <Grid item xs="auto">
                          <Box component={Typography} variant="h3" marginBottom="0!important">Church Information</Box>
                        </Grid>
                        <Grid item xs="auto">
                          <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                            {AccountEditState
                            ?<Button variant="contained" size="small" onClick={handleAccountSaveClick} disabled={LoadingState}>SAVE</Button>
                            :<IconButton onClick={handleAccountEditClick}><EditIcon/></IconButton>}
                          </Box>
                        </Grid>
                      </Grid>}>
                  </CardHeader>
                  <CardContent>
                    <Box className={classes.plLg4}>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Name</FormLabel>
                          <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled={!AccountEditState}>
                            <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={ChurchNameState} onChange={handleChurchNameChange} placeholder='Church Name'/>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Congregation Size</FormLabel>
                          <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled={!AccountEditState}>
                            <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={SelectInput} autoComplete="off" type="text" value={CongregationState} onChange={handleCongregationSizeChange}>
                              <MenuItem value={'Less than 100'}>Less than 100</MenuItem>
                              <MenuItem value={'100 - 300'}>100 - 300</MenuItem>
                              <MenuItem value={'300 - 500'}>300 - 500</MenuItem>
                              <MenuItem value={'More than 500'}>More than 500</MenuItem>
                            </Box>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>State</FormLabel>
                          <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled={!AccountEditState}>
                            <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={SelectInput} autoComplete="off" type="text" value={StateState} onChange={handleStateChange}>
                              {states.map(state=><MenuItem value={state.abbreviation} key={state.abbreviation}>{state.name}</MenuItem>)}
                            </Box>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>City</FormLabel>
                          <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled={!AccountEditState}>
                            <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={CityState} onChange={handleCityChange} placeholder='City'/>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Zip</FormLabel>
                          <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled={!AccountEditState}>
                            <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={ZipState} onChange={handleZipChange} placeholder='Zip code'/>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    </Box>
                </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} component={Box} marginBottom="3rem" classes={{ root: classes.gridItemRoot + " " + classes.order2 }}>
                <Card classes={{root: classes.cardRoot + " " + classes.cardRootSecondary,}}>
                  <CardHeader classes={{ root: classes.cardHeaderRoot }}
                    subheader={
                      <Grid container component={Box} alignItems="center" justifyContent="space-between">
                        <Grid item xs="auto">
                          <Box component={Typography} variant="h3" marginBottom="0!important">Billing</Box>
                        </Grid>
                      </Grid>
                    }/>
                  <CardContent>
                  <Box className={classes.plLg4}>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormGroup>
                          {
                          BillingState.subscribed ?
                          <Grid container>
                            <Grid item md={6} sm={12} xs={12}>
                                <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled>
                                    <Box paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={"Subscribed on: " + BillingState.billingDate}/>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <Button variant="contained" classes={{ root: classes.removeButtonRoot }} onClick={handleCancelSubscriptionClick} >
                                    <Box component="span" >Cancel Subscription</Box>
                                </Button>
                            </Grid>
                          </Grid>
                          :
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Box component={Typography} variant="h4">Birdseye Analytics Subscription</Box>
                              <Box component={Typography} variant="body2">US${SubscriptionPrice} / month</Box>
                            </Grid>
                            <Grid item xs={12}>
                              <form onSubmit={handleSubscribeClick}>
                                <Button variant="contained" size="small" type="submit" disabled={LoadingState}>Subscribe</Button>
                              </form>
                            </Grid>
                          </Grid>
                          }
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} component={Box} marginBottom="3rem" classes={{ root: classes.gridItemRoot + " " + classes.order2 }}>
                <Card classes={{root: classes.cardRoot + " " + classes.cardRootSecondary,}}>
                  <CardHeader classes={{ root: classes.cardHeaderRoot }}
                    subheader={
                      <Grid container component={Box} alignItems="center" justifyContent="space-between">
                        <Grid item xs="auto">
                          <Box component={Typography} variant="h3" marginBottom="0!important">Support</Box>
                        </Grid>
                      </Grid>
                    }/>
                  <CardContent>
                  <Box className={classes.plLg4}>
                    <FormGroup>
                      <Grid container>
                        <Grid item md={6} sm={12} xs={12}>
                            <Button variant="contained" size="small" onClick={()=>window.location.href="mailto:support@birdseyechurch.com"}>
                                <Box component="span" >Contact Us</Box>
                            </Button>
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Box>
                </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={6} component={Box} marginBottom="3rem" classes={{ root: classes.gridItemRoot + " " + classes.order2 }}>
            <Card classes={{root: classes.cardRoot + " " + classes.cardRootSecondary,}}>
              <CardHeader classes={{ root: classes.cardHeaderRoot }}
                subheader={
                  <Grid container component={Box} alignItems="center" justifyContent="space-between">
                    <Grid item xs="auto">
                      <Box component={Typography} variant="h3" marginBottom="0!important">Sources</Box>
                    </Grid>
                  </Grid>
                }/>
              <CardContent classes={{ root: BillingState.subscribed ? '' : classes.blur}}>
                <Box component={Typography} variant="h6" color={theme.palette.gray[600] + "!important"} paddingTop=".25rem" paddingBottom=".25rem" fontSize=".75rem!important" letterSpacing=".04em" marginBottom="1.5rem!important" classes={{ root: classes.typographyRootH6 }}>Attract Data</Box>
                <Box className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ShareButton
                        disabled={!BillingState.subscribed}
                        onShareClick={handleFacebookClick}
                        onRemoveClick={handleFacebookRemoveClick}
                        classes={classes}
                        source={FacebookState}
                        shared={FacebookState.shared}
                        buttonLabel={'Facebook'}
                        label={'Facebook'}
                        imageSrc={require("assets/img/icons/common/facebook.svg").default}/>
                    </Grid>
                    <Grid item xs={12}>
                      <ShareButton
                        disabled={!BillingState.subscribed}
                        classes={classes}
                        source={InstagramState}
                        shared={InstagramState.shared}
                        buttonLabel={'Instagram'}
                        label={'Instagram'}
                        comingSoon={true}
                        imageSrc={require("assets/img/icons/common/instagram.svg").default}/>
                    </Grid>
                    <Grid item xs={12}>
                      <ShareButton
                        disabled={!BillingState.subscribed}
                        classes={classes}
                        source={YoutubeState}
                        shared={YoutubeState.shared}
                        onShareClick={handleYoutubeClick}
                        onRemoveClick={handleYoutubeRemoveClick}
                        buttonLabel={'YouTube'}
                        label={'YouTube'}
                        comingSoon={true}
                        imageSrc={require("assets/img/icons/common/youtube.svg").default}/>
                    </Grid>
                    <Grid item xs={12}>
                      <ShareButton
                        disabled={!BillingState.subscribed}
                        classes={classes}
                        source={GoogleAnalyticsState}
                        shared={GoogleAnalyticsState.shared}
                        onShareClick={handleGoogleAnalyticsClick}
                        onRemoveClick={handleGoogleAnalyticsRemoveClick}
                        buttonLabel={'Google Analytics'}
                        label={'Google Analytics'}
                        comingSoon={true}
                        imageSrc={require("assets/img/icons/common/google-analytics.svg").default}/>
                    </Grid>
                  </Grid>
                </Box>
                <Box component={Divider} marginBottom="1.5rem!important" marginTop="1.5rem!important" disabled/>
                <Box component={Typography} variant="h6" color={theme.palette.gray[600] + "!important"} paddingTop=".25rem" paddingBottom=".25rem" fontSize=".75rem!important" letterSpacing=".04em" marginBottom="1.5rem!important" classes={{ root: classes.typographyRootH6 }}>Engage Data</Box>
                <Box className={classes.plLg4}>
                  <Grid container>
                  <Grid item xs={12}>
                      <ShareButton
                        disabled={!BillingState.subscribed}
                        classes={classes}
                        source={GoogleSheetsState}
                        shared={GoogleSheetsState.shared}
                        onShareClick={handleGoogleSheetClick}
                        onRemoveClick={handleGoogleSheetRemoveClick}
                        buttonLabel={'Google Sheet'}
                        label={'Google Sheet'}
                        imageSrc={require("assets/img/icons/common/google-sheets.svg").default}/>
                    </Grid>
                    {BillingState.subscribed ?
                      <Grid item xs={12}style={{marginBottom:'5%', marginTop:'-1%'}}>
                        <a href={process.env.REACT_APP_GS_TEMPLATE} target="_blank" rel="noreferrer">View Template</a>
                      </Grid>
                      : null
                    }
                    <Grid item xs={12}>
                      <ShareButton
                        disabled={!BillingState.subscribed}
                        classes={classes}
                        source={PlanningCenterPeopleState}
                        shared={PlanningCenterPeopleState.shared}
                        onShareClick={handlePlanningCenterPeopleClick}
                        onRemoveClick={handlePlanningCenterPeopleRemoveClick}
                        buttonLabel={'PC People'}
                        label={'Planning Center People'}
                        imageSrc={require("assets/img/icons/common/people.png").default}
                        buttonFooter={!PlanningCenterGivingState.shared ? <FormControlLabel control={<Checkbox value={PlanningCenterGivingCheckboxState} onChange={handlePlanningCenterGivingCheckbox} classes={{ root: classes.checkeboxRoot }}/>} label="Include Planning Center Giving data?" /> : null}/>
                    </Grid>
                    {PlanningCenterPeopleState.shared ?
                    <Grid item xs={12}>
                      <ShareButton
                        disabled={!BillingState.subscribed}
                        classes={classes}
                        source={PlanningCenterGivingState}
                        shared={PlanningCenterGivingState.shared}
                        onShareClick={handlePlanningCenterGivingClick}
                        onRemoveClick={handlePlanningCenterGivingRemoveClick}
                        buttonLabel={'PC Giving'}
                        label={'Planning Center Giving'}
                        imageSrc={require("assets/img/icons/common/giving.png").default}
                      />
                    </Grid>
                    : null}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <GoogleSheetUrlDialog open={GoogleSheetUrlDialogState} onClick={handleGoogleSheetLinkClick} classes={classes} theme={theme} handleLinkChange={handleGoogleSheetsLinkChange}/>
      <GoogleSheetDialog open={GoogleSheetDialogState} onClick={handleGoogleSheetContinueClick} onClose={()=>setGoogleSheetDialogState(false)}/>
      <Snackbar open={ErrorState.error} autoHideDuration={10000} onClose={()=>setErrorState(false)}>
        <Alert onClose={()=>setErrorState(false)} severity="error">
          {ErrorState.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Account;
