import {useState, useContext, useEffect} from "react";
import PropTypes from "prop-types";
import { useLocation, Link, useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {AppBar, Box, Container, Divider, List, ListItem, Menu, Toolbar, Typography} from "@material-ui/core/";
// @material-ui/icons components
import Clear from "@material-ui/icons/Clear";
import MenuIcon from "@material-ui/icons/Menu";
import LogoutIcon from '@material-ui/icons/ExitToApp';

// User context
import {UserContext} from 'assets/plugins/contexts/userContext';

// core components
import componentStyles from "assets/theme/components/sidebar.js";

//Firebase
import {logOut} from 'assets/plugins/firebase/auth';
import {getUser} from 'assets/plugins/firebase/db';

const useStyles = makeStyles(componentStyles);

const Sidebar = ({ routes, logo, dropdown, input }) => {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [ChurchState, setChurchState] = useState(null);

  const { currentUser } = useContext(UserContext);
  const history = useHistory();

  // Fetch User
    useEffect(()=>{
      const fetchUser = async () =>{
        try {
          const userDocumentReference = await getUser(currentUser.user);
          const user = userDocumentReference.data();
          setChurchState(user.church);
        } catch (error) {
          console.error(error);
        }

      };
      
      fetchUser();
    },[currentUser.user]);

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = async () => {
    try {
      await logOut();
      history.push('/');
    } catch (error) {
      console.error(error);
    };
  };

  const menuId = "responsive-menu-id";

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {

    return routes.map( (prop, key) => {

      if (prop.divider) {
        return <Divider key={key} classes={{ root: classes.divider }} />;
      } else if (prop.title) {
        return (
          <Typography key={key} variant="h6" component="h6" classes={{ root: classes.title }}>
            {prop.title}
          </Typography>
        );
      }

      let textContent = (
        <>
          <Box marginLeft={`${prop.indentation}rem`} minWidth="2.25rem" display="flex" alignItems="center">
            {
              typeof prop.icon === "string" ? 
                (<Box component="i" className={prop.icon + " " + classes["text" + prop.iconColor]}/>) 
                : null
            }
            {
              typeof prop.icon === "object" ? 
              (<Box component={prop.icon} width="1.25rem!important" height="1.25rem!important" className={classes["text" + prop.iconColor]}/>) 
              : null
            }
          </Box>
          {prop.name}
        </>
      );

      if (prop.href) {
        return (
          <ListItem
            key={key}
            component={"a"}
            href={prop.href}
            onClick={handleMenuClose}
            classes={{
              root:
                classes.listItemRoot +
                (prop.upgradeToPro
                  ? " " + classes.listItemRootUpgradeToPro
                  : ""),
              selected: classes.listItemSelected,
            }}
            target="_blank"
            selected={prop.upgradeToPro === true}
          >
            {textContent}
          </ListItem>
        );
      } else if(prop.layout === '/admin'){
        return (
          <ListItem key={key} component={Link} onClick={handleMenuClose} to={prop.layout + prop.path} classes={{root: classes.listItemRoot + (prop.upgradeToPro ? " " + classes.listItemRootUpgradeToPro: ""),selected: classes.listItemSelected,}} selected={location.pathname === prop.layout + prop.path || prop.upgradeToPro === true}>
            {textContent}
          </ListItem>
        );
      }

      return null;
    });
  };

  let logoImage = (<img alt={logo.imgAlt} className={classes.logoClasses} src={logo.imgSrc} />);
  let logoObject =
    (logo && logo.innerLink)  ? (<Link to={logo.innerLink} className={classes.logoLinkClasses}>{logoImage}</Link>) 
    : logo && logo.outterLink ? (<a href={logo.outterLink} className={classes.logoLinkClasses}>{logoImage}</a>) 
    : null;

  return (
    <>
      <AppBar position="relative" color={"primary"} elevation={0}>
        <Toolbar>
          <Container display="flex!important" justifyContent="space-between" alignItems="center" marginTop=".75rem" marginBottom=".75rem" component={Box} maxWidth={false} padding="0!important">
            <Box color={"secondary"} component={MenuIcon} width="2rem!important" height="2rem!important" aria-controls={menuId} aria-haspopup="true" onClick={handleMenuOpen}/>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              {logoObject}
              <Typography color={"secondary"} style={{fontWeight: 400}}>{ChurchState ? ChurchState.name.toUpperCase() : null}</Typography>
            </Box>
            {dropdown}
          </Container>
        </Toolbar>
      </AppBar>
      <Menu anchorEl={anchorEl} anchorOrigin={{ vertical: "top", horizontal: "right" }} id={menuId} keepMounted transformOrigin={{ vertical: "top", horizontal: "right" }} open={isMenuOpen} onClose={handleMenuClose} classes={{ paper: classes.menuPaper }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" paddingLeft="1.25rem" paddingRight="1.25rem" paddingBottom="1rem" className={classes.outlineNone}>
            {logoObject}
            <Box component={Clear} width="2rem!important" height="2rem!important" aria-controls={menuId} aria-haspopup="true" onClick={handleMenuClose}/>
          </Box>
          <Box component={Divider} marginBottom="1rem!important" marginLeft="1.25rem!important" marginRight="1.25rem!important"/>
          <Box paddingLeft="1.25rem" paddingRight="1.25rem">
            {input}
          </Box>
          <List classes={{ root: classes.listRoot }}>
            {createLinks(routes)}
            <ListItem key={'logout'} component={Link} onClick={(e)=>handleLogoutClick(e)} to={'/auth/login'} classes={{root:classes.listItemRoot,selected: classes.listItemSelected,}}>
              <>
                <Box minWidth="2.25rem" display="flex" alignItems="center">
                    <Box component="i" className={LogoutIcon + " "}/>
                    <Box component={LogoutIcon} width="1.25rem!important" height="1.25rem!important" />
                </Box>
                {'Logout'}
              </>
            </ListItem>
          </List>
        </Menu>
    </>
  );
}

export default Sidebar;

Sidebar.defaultProps = {
  routes: [],
};

Sidebar.propTypes = {
  // this is the input/component that will be rendered on responsive
  // in our demo, we add this input component since the AdminNavbar
  // will not be visible on responsive mode
  input: PropTypes.node,
  // this is the dropdown/component that will be rendered on responsive
  // in our demo, it is the same with the dropdown from the AdminNavbar
  // since the AdminNavbar will not be visible on responsive mode
  dropdown: PropTypes.node,
  // NOTE: we recommend that your logo has the following dimensions
  // // 135x40 or 487x144 or a resize of these dimensions
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // this generates an anchor (<a href="href">..</a>) link
      // this is a link that is sent outside the app
      PropTypes.shape({
        // if this is set to true, than the link will have an absolute position
        // use wisely and with precaution
        upgradeToPro: PropTypes.bool,
        href: PropTypes.string,
        name: PropTypes.string,
        icon: PropTypes.oneOfType([
          // this refers to icons such as ni ni-spaceship or fa fa-heart
          PropTypes.string,
          // this refers to icons from @material-ui/icons
          PropTypes.object,
        ]),
        iconColor: PropTypes.oneOf([
          "Primary",
          "PrimaryLight",
          "Error",
          "ErrorLight",
          "Warning",
          "WarningLight",
          "Info",
          "InfoLight",
        ]),
      }),
      // this generates a Link (<Link to="layout + path">..</Link>) link
      // this is a link that is sent inside the app
      PropTypes.shape({
        path: PropTypes.string,
        name: PropTypes.string,
        layout: PropTypes.string,
        component: PropTypes.func,
        icon: PropTypes.oneOfType([
          // this refers to icons such as ni ni-spaceship or fa fa-heart
          PropTypes.string,
          // this refers to icons from @material-ui/icons
          PropTypes.object,
        ]),
        iconColor: PropTypes.oneOf([
          "Primary",
          "PrimaryLight",
          "Error",
          "ErrorLight",
          "Warning",
          "WarningLight",
          "Info",
          "InfoLight",
        ]),
      }),
      // this is just a title without any action on it
      // you can think of it as a disabled link
      PropTypes.shape({
        title: PropTypes.string,
      }),
      // this is just a divider line
      PropTypes.shape({
        divider: true,
      }),
    ])
  ),
};
