// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Box, Card, CardContent, Grid} from "@material-ui/core";

// core components
import componentStyles from "assets/theme/views/auth/login.js";

const useStyles = makeStyles(componentStyles);

const RegisterSuccessful = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12} lg={5} md={7}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardContent classes={{ root: classes.cardContent }}>
            <Box color={theme.palette.gray[600]} textAlign="center" marginBottom="1rem" marginTop=".5rem" fontSize="1rem">
              <Box fontSize="80%" fontWeight="400" component="small">An email has been sent to the address provided.  Please verify your account, and continue on to log into your Birdseye Analytics dashboard.</Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default RegisterSuccessful;
