const componentStyles = (theme) => ({
    loadingDefault:{
        color: theme.palette.primary.main
    },
    typographyRootH1: {
        color: theme.palette.primary.dark,
        fontSize: "2.75rem",
        lineHeight: 1.5,
    },
});

export default componentStyles;
