// core components
import Dashboard from "views/admin/Dashboard.js";
import Giving from "views/admin/Giving";
// import Attendance from "views/admin/Attendance";
import Login from "views/auth/Login.js";
import Profile from "views/admin/Profile.js";
import Account from "views/admin/Account.js";
import Register from "views/auth/Register.js";
import PasswordRecovery from "views/auth/PasswordRecovery";
import RegisterSuccessful from "views/auth/RegisterSuccessful";
import RecoverySuccessful from "views/auth/RecoverySuccessful";
import PasswordRecoverySuccessful from "views/auth/PasswordRecoverySuccessful";
import EmailActionHandler from "views/auth/EmailActionHandler";
// @material-ui/icons components
import AccountCircle from "@material-ui/icons/AccountCircle";
import Person from "@material-ui/icons/Person";
import Tv from "@material-ui/icons/Tv";
import VpnKey from "@material-ui/icons/VpnKey";

var routes = [
  {
    path: "/index",
    name: "Overview",
    icon: Tv,
    iconColor: "Secondary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/giving",
    name: "Giving",
    icon: Tv,
    iconColor: "Secondary",
    component: Giving,
    layout: "/admin",
    indentation: 1
  },
  // {
  //   path: "/social",
  //   name: "Social",
  //   icon: Tv,
  //   iconColor: "Secondary",
  //   component: null,
  //   layout: "/admin",
  //   indentation: 1
  // },
  // {
  //   path: "/attendance",
  //   name: "Attendance",
  //   icon: Tv,
  //   iconColor: "Secondary",
  //   component: Attendance,
  //   layout: "/admin",
  //   indentation: 1
  // },
  // {
  //   path: "/volunteer",
  //   name: "Volunteer",
  //   icon: Tv,
  //   iconColor: "Secondary",
  //   component: null,
  //   layout: "/admin",
  //   indentation: 1
  // },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: Person,
    iconColor: "Secondary",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/account-manager",
    name: "Account Manager",
    icon: AccountCircle,
    iconColor: "Secondary",
    component: Account,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: VpnKey,
    iconColor: "Info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: AccountCircle,
    iconColor: "ErrorLight",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/passwordrecovery",
    name: "Password Recovery",
    icon: AccountCircle,
    iconColor: "Error",
    component: PasswordRecovery,
    layout: "/auth",
  },
  {
    path: "/registersuccessful",
    name: "Register Successful",
    icon: AccountCircle,
    iconColor: "Error",
    component: RegisterSuccessful,
    layout: "/auth",
  },
  {
    path: "/recoverysuccessful",
    name: "Recovery Successful",
    icon: AccountCircle,
    iconColor: "Error",
    component: RecoverySuccessful,
    layout: "/auth",
  },
  {
    path: "/passwordrecoverysuccessful",
    name: "Password Recovery Successful",
    icon: AccountCircle,
    iconColor: "Error",
    component: PasswordRecoverySuccessful,
    layout: "/auth",
  },
  {
    path: "/action",
    name: "Action handler",
    icon: AccountCircle,
    iconColor: "Error",
    component: EmailActionHandler,
    layout: "/auth",
  },
  {
    divider: true,
  },
];
export default routes;
