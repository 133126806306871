// React
import { useState } from 'react';

// Material
import { Grid, Button, Dialog, DialogActions, Typography, DialogContent, DialogTitle, Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const GoogleSheetDialog = ({open, onClick, onClose}) => {
    
    const steps = {
        "Step 1": <div>Make a copy of <a href={process.env.REACT_APP_GS_TEMPLATE} target="_blank" rel="noreferrer">this template</a>.</div>,
        "Step 2": "After a copy of the template has been created under your own Google account, be sure to rename the spreadsheet title to reflect your church's branding.",
        "Step 3": "Be sure to NOT change any of the column names.  Changing these column names will result in errors for your dashboard.",
        "Step 4": "Next, be sure to add in the appropriate service dates that you would like to populate the data for within your dashboard.  If you would like to add data from previous service date, that is great!  The more the merrier!",
        "Step 5": "For any data that you do not have, simply leave the cell blank.",
        "Step 6": "Once you have followed these steps, simply click CONTINUE.",
        "Note": "In order for your dashboard to be up to date with your church's weekly data, you must ensure to enter the appropriate data in the cells per church service date.",
    };

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle><Typography variant="h1">Google Sheet data</Typography></DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {Object.entries(steps)
                    .map(([nstep, step])=>
                        <Step key={nstep}>
                            <StepLabel StepIconComponent={RadioButtonCheckedIcon}><Typography variant="h4">{nstep}</Typography></StepLabel>
                            <StepContent>
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">{step}</Typography>
                                    </Grid>
                                    <Grid item xs={12} container justify='start'>
                                        <Button variant="outlined" disabled={activeStep === 0} onClick={handleBack}>BACK</Button>
                                        {
                                            activeStep === 6 ? <img onClick={onClick} style={{cursor:"pointer"}} alt='Google Button' src={require("assets/img/icons/common/btn_google_signin.png").default} height="100%"/>:
                                            <Button variant="contained" onClick={handleNext} style={{marginLeft:"1rem"}}>
                                                NEXT
                                            </Button>
                                        }       
                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>
                    )}
                </Stepper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">CANCEL</Button>
            </DialogActions>
        </Dialog>
    );
}

export default GoogleSheetDialog;