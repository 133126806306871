// React 
import { useEffect, useState, useContext } from 'react';

// Routing 
import { Link } from "react-router-dom";

// Firestore
import { getUser } from 'assets/plugins/firebase/db';

// User Context
import { UserContext } from 'assets/plugins/contexts/userContext';

// Components
import Message from "components/Message";

// @material-ui
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Grid } from "@material-ui/core";

import componentStyles from "assets/theme/views/admin/dashboard.js";

const useStyles = makeStyles(componentStyles);

const Dashboard = () => {
  const classes = useStyles();

  // Contexts
  const { currentUser } = useContext(UserContext);

  const [AccountCompleteState, setAccountCompleteState] = useState(false);
  const [ErrorState, setErrorState] = useState({error:false, message:""});

  useEffect(()=>{
    const getAccountComplete = async () => {
      try {
        const response = await getUser(currentUser.user);
        const user = response.data();

        const sourceShared = user.billing.subscribed && Object.values(user.sources).map(source=>source.shared).includes(true);

        setAccountCompleteState(sourceShared);

      } catch (error) {
        setErrorState({error:false, message: error})
      }
    };
    getAccountComplete();
  },[ currentUser ]);

  if(!AccountCompleteState || ErrorState.error){
    return <Message text={<div>Finish setting up your account in the <Link to="/admin/account-manager">Account Manager</Link> section of the portal</div>}/>
  };

  return (
    <Container maxWidth={false} component={Box} marginTop="3rem" classes={{ root: classes.containerRoot }}>
      <Grid container spacing={2}>
        <Grid item xs={12} component={Box} marginBottom="3rem" classes={{ root: classes.gridItemRoot + " " + classes.order2 }}>
          <iframe title="overview" className={classes.responsiveIframe} src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_DOMO_API_ROUTE}${process.env.REACT_APP_DOMO_API_DASHBOARD_ENDPOINT}?embedID=${process.env.REACT_APP_DOMO_DASHBOARD_EID}&accountID=${currentUser.user.uid}`}/>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
