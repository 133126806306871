import { updateUserYoutubeSource } from "assets/plugins/firebase/db";
import axios from "axios";

export const youtubeLogin = () =>{
    const SCOPE = process.env.REACT_APP_YOUTUBE_SCOPE;
    const GoogleAuth = window.gapi.auth2.getAuthInstance();

    return GoogleAuth.grantOfflineAccess({
        access_type: 'offline',
        prompt: 'consent',
        scope: SCOPE
    });

};

export const youtubeAPIAuth = youtubeResponse =>{
    return axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GOOGLE_API_URL}${youtubeResponse.code}`);
};

export const youtubeFirebaseUpdate = (user, data) =>{
    const today = new Date();
    const todayF = `${('0'+(today.getMonth() + 1)).slice(-2)}/${('0'+(today.getDate())).slice(-2)}/${today.getFullYear()}`;
    
    return updateUserYoutubeSource({
        shared: data.shared,
        uid: user.uid,
        secretId: data.shared ? data.secretId : null,
        sharedSince: data.shared ? todayF : null
    });
};