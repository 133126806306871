import {db} from './firebase';

if(process.env.NODE_ENV !== "production" ){
    db.useEmulator("localhost", 8080);
    db.clearPersistence().catch(error => {
        console.error('Could not enable persistence:', error.code);
    });
};

export const getUser = user => db.collection('users').doc(user.uid).get();

export const createUser = user => db.collection('users').doc(user.uid).set({
    billing:{
        billingDate:'',
        subscribed: false,
        subscriptionId:''
    },
    church:{
        congregationSize: user.church.congregationSize,
        name: user.church.name,
        location:{
            state: user.church.state,
            city: user.church.city,
            zip: user.church.zipCode
        }
    },
    sources:{
        facebook:{
            shared:false,
            sharedSince: null,
            lastImport: null,
            successfulImports: null,
            status: null,
            dateRange: null,
            totalRecords: 0
        },
        googleAnalytics:{
            shared:false,
            sharedSince: null,
            lastImport: null,
            successfulImports: null,
            status: null,
            dateRange: null,
            totalRecords: 0
        },
        googleSheets:{
            shared:false,
            sharedSince: null,
            lastImport: null,
            successfulImports: null,
            status: null,
            dateRange: null,
            totalRecords: 0
        },
        instagram:{
            shared:false,
            sharedSince: null,
            lastImport: null,
            successfulImports: null,
            status: null,
            dateRange: null,
            totalRecords: 0
        },
        planningCenterPeople:{
            shared:false,
            sharedSince: null,
            lastImport: null,
            successfulImports: null,
            status: null,
            dateRange: null,
            totalRecords: 0,
            scope: ""
        },
        planningCenterGiving:{
            shared:false,
            sharedSince: null,
            lastImport: null,
            successfulImports: null,
            status: null,
            dateRange: null,
            totalRecords: 0,
            scope: ""
        },
        youtube:{
            shared:false,
            sharedSince: null,
            lastImport: null,
            successfulImports: null,
            status: null,
            dateRange: null,
            totalRecords: 0
        },
    },
    firstName:'',
    lastName:'',
    mobile:'',
    title:''
});

export const updateUserAccount = user => db.collection('users').doc(user.uid).update({
    firstName: user.firstName,
    lastName: user.lastName,
    mobile: user.mobile,
    title: user.title
});

export const updateUserChurch = user => db.collection('users').doc(user.uid).update({
    church:{
        congregationSize: user.church.congregationSize,
        location:{
            city: user.church.location.city,
            state: user.church.location.state,
            zip: user.church.location.zip
        },
        name: user.church.name
    }
});

export const updateUserBilling = user => db.collection('users').doc(user.uid).update({
    billing:{
        subscribed: user.subscribed,
        billingDate: user.date || '',
        subscriptionId: user.subscriptionId || ''
    },
});

export const updateUserPlanningCenterPeopleSource = user => db.collection('users').doc(user.uid).update({
    'sources.planningCenterPeople':{
        shared: user.shared,
        sharedSince: user.planningCenter.sharedSince,
        secretId: user.planningCenter.secretId
    }
});

export const updateUserPlanningCenterGivingSource = user => db.collection('users').doc(user.uid).update({
    'sources.planningCenterGiving':{
        shared: user.shared,
        sharedSince: user.planningCenter.sharedSince,
        secretId: user.planningCenter.secretId
    }
});

export const updateUserFacebookSource = user => db.collection('users').doc(user.uid).update({
    'sources.facebook':{
        shared: user.shared,
        sharedSince: user.sharedSince,
        secretId: user.secretId
    }
});

export const updateUserGoogleSheetSource = user => db.collection('users').doc(user.uid).update({
    'sources.googleSheets':{
        shared: user.shared,
        sharedSince: user.sharedSince,
        secretId: user.secretId,
        spreadsheetLink: user.spreadsheetLink
    }
});

export const updateUserYoutubeSource = user => db.collection('users').doc(user.uid).update({
    'sources.youtube':{
        shared: user.shared,
        sharedSince: user.sharedSince,
        secretId: user.secretId
    }
});

export const updateUserGoogleAnalyticsSource = user => db.collection('users').doc(user.uid).update({
    'sources.googleAnalytics':{
        shared: user.shared,
        sharedSince: user.sharedSince,
        secretId: user.secretId
    }
});