//React
import {useState, useContext} from "react";
//Routing
import { useHistory } from "react-router";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import LogoutIcon from '@material-ui/icons/ExitToApp';
import Person from "@material-ui/icons/Person";

// core components
import componentStyles from "assets/theme/components/navbar-dropdown.js";

// User Context
import {UserContext} from 'assets/plugins/contexts/userContext';

//Firebase
import {logOut} from 'assets/plugins/firebase/auth';

const useStyles = makeStyles(componentStyles);

const NavbarDropdown = () => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(false);
  const {currentUser} = useContext(UserContext);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(false);
  };

  const handleLogoutClick = async () =>{
    try {
      await logOut();
      history.replace('/');
    } catch (error) {
      console.error(error)
    };
  };

  const handleProfileClick = () =>{
    history.push('/admin/user-profile');
    handleMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu anchorEl={anchorEl} anchorOrigin={{ vertical: "top", horizontal: "right" }} id={menuId} keepMounted transformOrigin={{ vertical: "top", horizontal: "right" }} open={isMenuOpen} onClose={handleMenuClose}>
      <Typography variant="h6" component="h6" classes={{ root: classes.menuTitle }}>Welcome!</Typography>
      <Box display="flex!important" alignItems="center!important" component={MenuItem} onClick={handleProfileClick}>
        <Box component={Person} width="1.25rem!important" height="1.25rem!important" marginRight="1rem"/>
        <span>My profile</span>
      </Box>
      <Divider component="div" classes={{ root: classes.dividerRoot }} />
      <Box display="flex!important" alignItems="center!important" component={MenuItem} onClick={handleLogoutClick}>
        <Box component={LogoutIcon} width="1.25rem!important" height="1.25rem!important" marginRight="1rem"/>
        <span>Logout</span>
      </Box>
    </Menu>
  );

  return (
    <>
      <Button edge="end" aria-label="account of current user" aria-controls={menuId} aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit" classes={{label: classes.buttonLabel,root: classes.buttonRoot,}}>
        <Avatar alt="User Avatar" src={currentUser.photo} classes={{root: classes.avatarRoot,}}/>
        <Hidden smDown>{currentUser.user.displayName}</Hidden>
      </Button>
      {renderMenu}
    </>
  );
};

export default NavbarDropdown;
