// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Box, Grid, List, ListItem} from "@material-ui/core";

// core components
import componentStyles from "assets/theme/components/admin-footer.js";

const useStyles = makeStyles(componentStyles);

const Footer = () => {
  const classes = useStyles();
  return (
    <Box component="footer" width="100%" padding="2.5rem 0">
      {/* {!window.location.pathname.includes('index') && */}
      <Grid container classes={{ root: classes.justifyContentCenter }}>
        <Box item xs={12} xl={6} component={Grid} display="flex" alignItems="center" className={classes.justifyContentCenter}>
          <div className={classes.copyrightWrapper}> © {new Date().getFullYear()}{" "}
            <a className={classes.copyrightLink} href={process.env.REACT_APP_COMPANY_URL} rel="noopener noreferrer" target="_blank">
              Birdseye Analytics
            </a>
          </div>
        </Box>

        <Grid item xl={6} component={Box} display="flex" justifyContent="flex-end">
          <Box component={List} display="flex" justifyContent="center" alignItems="center" className={classes.flexDirectionColumn}>
            <ListItem component="a" href={process.env.REACT_APP_PRIVACY_URL} rel="noopener noreferrer" target="_blank" classes={{root: classes.listItemRoot,}}>
              Privacy
            </ListItem>

            <ListItem component="a" href={process.env.REACT_APP_TERMS_URL} rel="noopener noreferrer" target="_blank" classes={{root: classes.listItemRoot,}}>
              Terms & Conditions
            </ListItem>

            <ListItem component="p" href={process.env.REACT_APP_PRIVACY_URL} rel="noopener noreferrer" target="_blank" classes={{root: classes.listItemRoot,}}>
              {process.env.REACT_APP_VERSION}
            </ListItem>

          </Box>
        </Grid>
      </Grid>
      {/* } */}
    </Box>
  );
};

export default Footer;
