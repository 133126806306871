import { updateUserFacebookSource } from "assets/plugins/firebase/db";
import axios from "axios";

export const facebookFirebaseUpdate = (user, data, today) =>{
    return updateUserFacebookSource({
        shared: true,
        uid: user.uid,
        secretId: data.secretId,
        sharedSince: today
    });
};

export const facebookAPIAuth = facebookResponse =>{
    return axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_FB_API_URL}`,{
        userId: facebookResponse.authResponse.userID,
        userToken: facebookResponse.authResponse.accessToken
    });
};


export const facebookLogin = callback => {
    return new Promise((resolve, reject) => {
        window.FB.login((response) => {
            if (response.status === 'connected') {
                resolve(response)
            } else if (response.status === 'not_authorized') {
                window.FB.login(callback)
            }
            else {
                reject("Authentication Error")
            }
        }, {auth_type: 'reauthenticate', scope: process.env.REACT_APP_FB_SCOPE})
    })
};