import { auth, sessionAuthPersistence } from './firebase';

if(process.env.NODE_ENV !== "production" ){
    auth.useEmulator("http://localhost:9099");
};

export const setSessionPersistence = () => {
    return auth.setPersistence(sessionAuthPersistence);
};

export const emailLogin = (email, password) =>{
    return auth.signInWithEmailAndPassword(email, password);
};

export const authState = callback =>{
    return auth.onAuthStateChanged(callback);
};

export const logOut = () =>{
    return auth.signOut();
};

export const passwordReset = email =>{
    return auth.sendPasswordResetEmail(email);
};

export const updateUserProfile = user =>{
    return auth.currentUser.updateProfile(user);
};

export const createUserEmail = (email, password) =>{
    return auth.createUserWithEmailAndPassword(email, password);
};

export const verifyActionCode = (actionCode, mode) =>{
    switch (mode) {
        case 'resetPassword':
            return auth.verifyPasswordResetCode(actionCode);
        case 'recoverEmail':
            return auth.checkActionCode(actionCode);
        case 'verifyEmail':
            return auth.applyActionCode(actionCode);
        default:
            return 'Invalid code';
    }
};

export const confirmPasswordReset = (actionCode, newPassword) => {
    return auth.confirmPasswordReset(actionCode, newPassword);
};