//React
import {useState} from "react";

// @material-ui
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Box, Button, Card, CardContent, FilledInput, FormControl, Grid, InputAdornment, Snackbar} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import Email from "@material-ui/icons/Email";

// core components
import componentStyles from "assets/theme/views/auth/login.js";

//Firebase
import { passwordReset } from 'assets/plugins/firebase/auth';

// Routing
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(componentStyles);

const PasswordRecovery = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [EmailState, setEmailState] = useState("");
  const [BadResetState, setBadResetState] = useState(false);
  const [ErrorMessageState, setErrorMessageState] = useState(false);

  const handleResetClick = async (event) => {
    event.preventDefault();
    try {
      await passwordReset(EmailState)
      history.push('/auth/recoverysuccessful');
    } catch (error) {
      setBadResetState(true);
      setErrorMessageState(error.message);
    };
  };

  const handleEmailChange = event =>{
    setEmailState(event.target.value);
  };

  const handleKeyPress = event =>{
    if(event.keyCode === 13){
      handleResetClick(event);
    };
  };

  return (
    <>
      <Grid item xs={12} lg={5} md={7}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardContent classes={{ root: classes.cardContent }}>
            <Box color={theme.palette.gray[600]} textAlign="center" marginBottom="1rem" marginTop=".5rem" fontSize="1rem">
              <Box fontSize="80%" fontWeight="400" component="small">We will send you and email to reset your password</Box>
            </Box>
            <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important">
              <FilledInput value={EmailState} onChange={handleEmailChange} onKeyDown={handleKeyPress} autoComplete="off" type="email" placeholder="Email" startAdornment={<InputAdornment position="start"><Email /></InputAdornment>}/>
            </FormControl>
            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
              <Button variant="contained" onClick={e=>handleResetClick(e)}>Send password reset</Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Snackbar open={BadResetState} autoHideDuration={10000} onClose={()=>setBadResetState(false)}>
        <Alert onClose={()=>setBadResetState(false)} severity="error">
          {ErrorMessageState}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PasswordRecovery;
