//Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, Typography } from '@material-ui/core';

// core components
import componentStyles from "assets/theme/components/loading.js";

const useStyles = makeStyles(componentStyles);

const Loading = () =>{

    const classes = useStyles();

    return(
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100vw" height="100vh">
            <Typography variant="h1" classes={{ root: classes.typographyRootH1 }}>Loading</Typography>
            <CircularProgress classes={{root: classes.defaultLoading}}/>
        </Box>
    );};

export default Loading