// React
import {useState, useContext, useEffect, useRef} from 'react';

// Firestore
import {getUser, updateUserAccount} from 'assets/plugins/firebase/db';
import { updateUserProfileImage } from 'assets/plugins/firebase/storage';
import { updateUserProfile } from 'assets/plugins/firebase/auth';

// User Context
import {UserContext} from 'assets/plugins/contexts/userContext';

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Box, Button, Card, CardContent, CardHeader, Container, FilledInput, FormControl, FormGroup, FormLabel, Grid, Typography, IconButton }from "@material-ui/core";

// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit';

// core components
import UserHeader from "components/Headers/UserHeader.js";

import componentStyles from "assets/theme/views/admin/profile.js";

const useStyles = makeStyles(componentStyles);

const Profile = () => {
  const classes = useStyles();
  const theme = useTheme();

  // Contexts
  const {currentUser} = useContext(UserContext);

  // States

  const [LoadingState, setLoadingState] = useState(false);
  const refImage = useRef("");

    // Editable forms states
  const [AccountEditState, setAccountEditState] = useState(false);

    // Account form fields states
  const [EmailState, setEmailState] = useState('');
  const [FirstNameState, setFirstNameState] = useState('');
  const [LastNameState, setLastNameState] = useState('');
  const [MobileState, setMobileState] = useState('');
  const [TitleState, setTitleState] = useState('');

    // Errors states
  const [MobileErrorState, setMobileErrorState] = useState(false);

  // Fetch User
  useEffect(()=>{
    const fetchUser = async () => {
      try {
        const response = await getUser(currentUser.user);
        const user = response.data();
        // console.log(user)
          // Set account form fields states
        setEmailState(currentUser.user.email);
        setFirstNameState(user.firstName);
        setLastNameState(user.lastName);
        setMobileState(user.mobile);
        setTitleState(user.title);
      } catch (error) {
        console.error(error);
      };
    };

    fetchUser();
  },[currentUser.user]);

  // What happens when user click edit buttons

  const handleAccountEditClick = () =>{
    setAccountEditState(true);
  }

  // What happens when user click save buttons

  const handleAccountSaveClick = async () =>{
    setLoadingState(true);
    const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(MobileState.match(phoneno)){
      try {
        await updateUserAccount({
          uid: currentUser.user.uid,
          firstName: FirstNameState,
          lastName: LastNameState,
          mobile: MobileState,
          title: TitleState
        });

        if(refImage.current.files[0]){
          const response = await updateUserProfileImage(refImage.current.files[0], currentUser.user.uid);
          const imageURL = await response.ref.getDownloadURL();
          await updateUserProfile({photoURL:imageURL});
          setAccountEditState(false);
        }
        else{
          setAccountEditState(false);
          setLoadingState(false);
        };
      } catch (error) {
        console.error(error);
      };
    }
    else{
      setMobileErrorState(true);
      setLoadingState(false);
    }
  };

  // Input handlers

    //Account inputs

  const handelEmailChange = e =>{
    setEmailState(e.target.value);
  }

  const handleFirstNameChange = e =>{
    setFirstNameState(e.target.value);
  }

  const handleLastNameChange = e =>{
    setLastNameState(e.target.value);
  }

  const handleMobileChange = e =>{
    setMobileErrorState(false);
    setMobileState(e.target.value);
  }

  const handleTitleChange = e =>{
    setTitleState(e.target.value);
  }

  return (
    <>
      <UserHeader title="Profile" subTitle=""/>
      {/* Page content */}
      <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
        <Grid container spacing={2}>
          <Grid item xs={12} component={Box} marginBottom="3rem" classes={{ root: classes.gridItemRoot + " " + classes.order2 }}>
            <Card classes={{root: classes.cardRoot + " " + classes.cardRootSecondary,}}>
              <CardHeader classes={{ root: classes.cardHeaderRoot }}
                subheader={
                  <Grid container component={Box} alignItems="center" justifyContent="space-between">
                    <Grid item xs="auto">
                      <Box component={Typography} variant="h3" marginBottom="0!important">Profile Manager</Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                        {AccountEditState 
                        ?<Button variant="contained" size="small" onClick={handleAccountSaveClick} disabled={LoadingState}>SAVE</Button>
                        :<IconButton onClick={handleAccountEditClick}><EditIcon/></IconButton>}
                      </Box>
                    </Grid>
                  </Grid>}>
              </CardHeader>
              <CardContent>
              <Box component={Typography} variant="h6" color={theme.palette.gray[600] + "!important"} paddingTop=".25rem" paddingBottom=".25rem" fontSize=".75rem!important" letterSpacing=".04em" marginBottom="1.5rem!important" classes={{ root: classes.typographyRootH6 }}> User Information</Box>
              <div className={classes.plLg4}>
              <Grid container>
                { AccountEditState ? 
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormLabel> Change Avatar</FormLabel>
                      <input type="file" accept="image/*" ref={refImage}/>
                    </FormGroup>
                  </Grid>
                :null}
                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel>Email</FormLabel>
                    <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled>
                      <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="email" value={EmailState} onChange={handelEmailChange} placeholder="example@example.com"/>
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormGroup>
                    <FormLabel>First name</FormLabel>
                    <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled={!AccountEditState}>
                      <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={FirstNameState} onChange={handleFirstNameChange} placeholder='Your first name'/>
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormGroup>
                    <FormLabel>Last Name</FormLabel>
                    <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled={!AccountEditState}>
                      <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={LastNameState} onChange={handleLastNameChange} placeholder='Your last name'/>
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormGroup>
                    <FormLabel>Mobile</FormLabel>
                    <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled={!AccountEditState}>
                      <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="tel" placeholder="Format: XXX-XXX-XXXX" value={MobileState} onChange={handleMobileChange} error={MobileErrorState}/>
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormGroup>
                      <FormLabel>Title</FormLabel>
                      <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important" disabled={!AccountEditState}>
                        <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={FilledInput} autoComplete="off" value={TitleState} onChange={handleTitleChange} placeholder="Church Title"/>
                      </FormControl>
                    </FormGroup>
                  </Grid>
              </Grid>
              </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Profile;