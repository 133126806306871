import { useEffect } from 'react';

const useFacebook = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.crossorigin="anonymous"
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        window.fbAsyncInit = () => {
            window.FB.init({
                appId: process.env.REACT_APP_FB_APP_ID,
                cookie: true,
                xfbml: true,
                version: 'v11.0'
            });

            console.log('Facebook loaded')
        };

        return () => {
            document.body.removeChild(script);
        }
    }, []);
};

export default useFacebook;