// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Button, Card, CardContent, Grid } from "@material-ui/core";

// core components
import componentStyles from "assets/theme/views/auth/login.js";

// Routing
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(componentStyles);

const PasswordRecoverySuccessful = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  return (
    <Grid item xs={12} lg={5} md={7}>
      <Card classes={{ root: classes.cardRoot }}>
        <CardContent classes={{ root: classes.cardContent }}>
          <Box color={theme.palette.gray[600]} textAlign="center" marginBottom="1rem" marginTop=".5rem" fontSize="1rem">
            <Box fontSize="80%" fontWeight="400" component="small">Success! Your password have been reseted</Box>
          </Box>
          <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
            <Button variant="contained" onClick={()=>history.push('/auth/login')}>Login</Button>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default PasswordRecoverySuccessful;
