import { useState, useEffect, useContext } from "react";

import { Redirect } from "react-router-dom";

import Loading from "components/Loading/Loading";

import { updateUserPlanningCenterPeopleSource, updateUserPlanningCenterGivingSource, updateUserBilling } from "assets/plugins/firebase/db";

// User Context
import { UserContext } from 'assets/plugins/contexts/userContext';

const UpdateUser = props =>{

    const [pending, setPending] = useState(true);

    // Contexts
    const { currentUser } = useContext(UserContext);

    useEffect(()=>{
        const today = new Date();
        const todayF = `${('0'+(today.getMonth() + 1)).slice(-2)}/${('0'+(today.getDate())).slice(-2)}/${today.getFullYear()}`;
        let updateUser = () => console.error("Error");

        const {provider, scope, secretId } = props.match.params; 

        switch(provider){
            case 'planningCenter': 
                const planningCenterData = {
                    shared:true,
                    uid: currentUser.user.uid,
                    planningCenter: {
                        sharedSince: todayF,
                        secretId: secretId
                    },
                };
                console.log(scope)
                if(scope === "people"){
                    updateUser = async () => {
                        await updateUserPlanningCenterPeopleSource(planningCenterData);
                        setPending(false);
                    };
                } else{
                    updateUser = async () => {
                        await updateUserPlanningCenterPeopleSource(planningCenterData);
                        await updateUserPlanningCenterGivingSource(planningCenterData);
                        setPending(false);
                    };
                };

                break;
            case 'payment': 

                const paymentData = {
                    uid: currentUser.user.uid,
                    subscribed: true,
                    date: todayF,
                    subscriptionId: secretId
                };

                updateUser = async () => {
                    await updateUserBilling(paymentData);
                    setPending(false);
                };

                break;
            default:
                break;
        };

        updateUser();

    },[props.match.params, currentUser.user.uid]);

    if (pending) {
        return <Loading/>
    }

    return(<Redirect to="/admin/account-manager"/>);
};

export default UpdateUser;