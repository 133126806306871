import {useState} from "react";

// Firestore
import { createUser } from 'assets/plugins/firebase/db';
import { createUserEmail } from 'assets/plugins/firebase/auth';

// Data
import {states} from 'assets/data/states';

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Box, Button, Card, CardContent, FilledInput, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, MenuItem, Select as SelectInput, Snackbar} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from '@material-ui/lab/Alert';
// @material-ui/icons components
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import MapIcon from '@material-ui/icons/Map';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';

// core components
import componentStyles from "assets/theme/views/auth/register.js";

//Password Regex
import {mediumPassword, strongPassword} from 'assets/variables/passwordRegex';

const useStyles = makeStyles(componentStyles);

const Register = ({history}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [EmailState, setEmailState] = useState("");
  const [ChurchNameState, setChurchNameState] = useState('');
  const [CongregationState, setCongregationState] = useState('Less than 100');
  const [StateState, setStateState] = useState('AL');
  const [CityState, setCityState] = useState('');
  const [ZipState, setZipState] = useState('');
  const [PasswordState, setPasswordState] = useState("");
  const [ConfirmPasswordState, setConfirmPasswordState] = useState("");
  const [SignUpErrorState, setSignUpErrorState] = useState(false);
  const [PasswordErrorState, setPasswordErrorState] = useState(false);
  const [FormErrorState, setFormErrorState] = useState(false);
  const [ErrorMessageState, setErrorMessageState] = useState('');
  const [PrivacyState, setPrivacyState] = useState(false);
  const [PasswordStrength, setPasswordStrength] = useState({strength:'weak', color:'red'});
  const [LoadingState, setLoadingState] = useState(false);

  const handleEmailChange = event =>{
    setEmailState(event.target.value);
  };

  const handleChurchNameChange = e =>{
    setChurchNameState(e.target.value);
  }

  const handleCongregationSizeChange = e =>{
    setCongregationState(e.target.value);
  }

  const handleStateChange = e =>{
    setStateState(e.target.value);
  }

  const handleCityChange = e =>{
    setCityState(e.target.value);
  }

  const handleZipChange = e =>{
    setZipState(e.target.value);
  };

  const handlePasswordChange = event =>{
      setPasswordState(event.target.value);

      if(strongPassword.test(event.target.value)) {
        setPasswordStrength({strength:'strong', color:theme.palette.success.main});
      } else if(mediumPassword.test(event.target.value)) {
        setPasswordStrength({strength:'medium', color:theme.palette.warning.dark});
      } else {
        setPasswordStrength({strength:'weak', color:theme.palette.error.main});
      }
  }

  const handleConfirmPasswordChange = event =>{
      if(PasswordErrorState){
        setPasswordErrorState(false);
      }
      setConfirmPasswordState(event.target.value);
  }

  const handleAlertClose = () =>{
    setFormErrorState(false);
    setPasswordErrorState(false);
    setSignUpErrorState(false);
  }

  const handleSignUpClick = async (event) => {

    event.preventDefault();

    if(PasswordState !== ConfirmPasswordState){
      setPasswordErrorState(true);
      setErrorMessageState('Passwords must match');
    }
    else if(!PrivacyState){
      setSignUpErrorState(true);
      setErrorMessageState('Privacy Policy is required');
    }
    else if(ChurchNameState === ""){
      setFormErrorState(true);
      setErrorMessageState('Church Name is required');
    }
    else if(CityState === ""){
      setFormErrorState(true);
      setErrorMessageState('City is required');
    }
    else if(ZipState === ""){
      setFormErrorState(true);
      setErrorMessageState('Zip code is required');
    }
    else{
      setLoadingState(true);
      try {
        const { user: firebaseAuthUser } = await createUserEmail(EmailState, PasswordState);
        firebaseAuthUser.sendEmailVerification({
          url: `${window.location.protocol}//${window.location.host}`
        });
        const user = {
          uid: firebaseAuthUser.uid,
          church:{
            name: ChurchNameState,
            congregationSize: CongregationState,
            state: StateState, 
            city: CityState, 
            zipCode: ZipState
          },
        };
        await createUser(user);
        history.push('/auth/registersuccessful');
      } catch (error) {
        setErrorMessageState(error.message)
        setSignUpErrorState(true);
        setLoadingState(false);
      };
    }
  };

  const handleKeyPress = event =>{
    if(event.keyCode === 13){
      handleSignUpClick(event);
    };
  }

  return (
    <>
      <Grid item xs={12} lg={6} md={8}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardContent classes={{ root: classes.cardContent }}>
            <Box color={theme.palette.gray[600]} textAlign="center" marginBottom="1.5rem" marginTop=".5rem" fontSize="1rem">
              <Box fontWeight="500">
                Sign up and experience Church Fuel today
              </Box>
            </Box>
            <FormGroup>
              <FormLabel>Church Name</FormLabel>
              <FormControl variant="filled" component={Box} width="100%" marginBottom="1.5rem!important">
                <FilledInput value={ChurchNameState} onChange={handleChurchNameChange} onKeyDown={handleKeyPress} autoComplete="off" type="text" placeholder="Church Name" startAdornment={<InputAdornment position="start"><HomeIcon /></InputAdornment>}/>
              </FormControl>
            </FormGroup>
            <FormGroup>
              <FormLabel>Congregation Size</FormLabel>
              <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important">
                <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={SelectInput} autoComplete="off" type="text" value={CongregationState} onChange={handleCongregationSizeChange} startAdornment={<InputAdornment position="start"><PeopleIcon /></InputAdornment>}>
                  <MenuItem value={'Less than 100'}>Less than 100</MenuItem>
                  <MenuItem value={'100 - 300'}>100 - 300</MenuItem>
                  <MenuItem value={'300 - 500'}>300 - 500</MenuItem>
                  <MenuItem value={'More than 500'}>More than 500</MenuItem>
                </Box>
              </FormControl>
            </FormGroup>
            <FormGroup>
              <FormLabel>State</FormLabel>
              <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important">
                <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={SelectInput} autoComplete="off" type="text" value={StateState} onChange={handleStateChange} startAdornment={<InputAdornment position="start"><MapIcon /></InputAdornment>}>
                  {states.map(state=><MenuItem value={state.abbreviation} key={state.abbreviation}>{state.name}</MenuItem>)}
                </Box>
              </FormControl>
            </FormGroup>
            <FormGroup>
              <FormLabel>City</FormLabel>
              <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important">
                <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={CityState} onChange={handleCityChange} placeholder='City' startAdornment={<InputAdornment position="start"><LocationCityIcon /></InputAdornment>}/>
              </FormControl>
            </FormGroup>
            <FormGroup>
              <FormLabel>Zip</FormLabel>
              <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important">
                <Box paddingLeft="0.75rem" paddingRight="0.75rem" component={FilledInput} autoComplete="off" type="text" value={ZipState} onChange={handleZipChange} placeholder='Zip code' startAdornment={<InputAdornment position="start"><MarkunreadMailboxIcon /></InputAdornment>}/>
              </FormControl>
            </FormGroup>
            <FormLabel>Account Information</FormLabel>
            <FormControl variant="filled" component={Box} width="100%" marginBottom="1.5rem!important">
              <FilledInput value={EmailState} onChange={handleEmailChange} onKeyDown={handleKeyPress} autoComplete="off" type="email" placeholder="Email" startAdornment={<InputAdornment position="start"><EmailIcon /></InputAdornment>}/>
            </FormControl>
            <FormControl variant="filled" component={Box} width="100%" marginBottom="1.5rem!important">
              <FilledInput value={PasswordState} onChange={handlePasswordChange} onKeyDown={handleKeyPress} autoComplete="off" type="password" placeholder="Password" error={PasswordErrorState} startAdornment={<InputAdornment position="start"><LockIcon /></InputAdornment>}/>
            </FormControl>
            <FormControl variant="filled" component={Box} width="100%" marginBottom="1.5rem!important">
              <FilledInput value={ConfirmPasswordState} onChange={handleConfirmPasswordChange} onKeyDown={handleKeyPress} autoComplete="off" type="password" placeholder="Confirm Password" error={PasswordErrorState} startAdornment={<InputAdornment position="start"><LockIcon /></InputAdornment>}/>
            </FormControl>
            <Box fontStyle="italic" fontSize="1rem" color={theme.palette.gray[600]} marginBottom=".5rem">
              <Box component="small" fontSize="80%">password strength:{" "}
                <Box component="span" fontWeight="700" color={PasswordStrength.color}>
                  {PasswordStrength.strength}
                </Box>
              </Box>
            </Box>
            <FormControlLabel value="end" control={<Checkbox checked={PrivacyState} labelPlacement="end" classes={{root: classes.formControlLabelRoot,label: classes.formControlLabelLabel,}} onChange={()=>setPrivacyState(!PrivacyState)}/>}
              label={
                <>
                  I agree with the{" "}
                  <Box color={theme.palette.secondary.main} component="a" textDecoration="none">Privacy Policy</Box>
                </>
              }/>
            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
              <Button variant="contained" onClick={e=>handleSignUpClick(e)} disabled={LoadingState}>Create account</Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Snackbar open={SignUpErrorState || PasswordErrorState || FormErrorState} autoHideDuration={10000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {ErrorMessageState}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Register;
