import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Box, Container, Grid, List, ListItem} from "@material-ui/core";

// core components
import componentStyles from "assets/theme/components/auth-footer.js";

const useStyles = makeStyles(componentStyles);

const Footer = () => {
  const classes = useStyles();
  return (
    <Box component="footer" width="100%" paddingTop="1rem">
      <Container component={Box} maxWidth="xl" display="flex!important" alignItems="center" classes={{root:classes.justifyContentCenter + " " + classes.flexDirectionColumn,}}>
        <Grid item xs={12} xl={6}>
          <div className={classes.copyrightWrapper}>
            © {new Date().getFullYear()}{" "}
            <a className={classes.copyrightLink} href="https://birdseyechurch.com/" rel="noopener noreferrer" target="_blank">
              Birdseye Analytics
            </a>
          </div>
        </Grid>
        <Grid item xs={12} xl={6} component={Box} display="flex" justifyContent="flex-end" classes={{root:classes.justifyContentCenter + " " + classes.flexDirectionColumn,}}>
          <Box component={List} display="flex" justifyContent="center" alignItems="center"
            classes={{root:classes.justifyContentCenter +" " +classes.flexDirectionColumn,}}>
            
            <ListItem component="a" href={process.env.REACT_APP_PRIVACY_URL} rel="noopener noreferrer" target="_blank" classes={{root: classes.listItemRoot,}}>
              Privacy
            </ListItem>

            <ListItem component="a" href={process.env.REACT_APP_TERMS_URL} rel="noopener noreferrer" target="_blank" classes={{root: classes.listItemRoot,}}>
              Terms & Conditions
            </ListItem>

            <ListItem component="p" href={process.env.REACT_APP_PRIVACY_URL} rel="noopener noreferrer" target="_blank" classes={{root: classes.listItemRoot,}}>
              {process.env.REACT_APP_VERSION}
            </ListItem>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
