import React from "react";
import {render} from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import theme from "assets/theme/theme.js";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import UpdateUser from "components/UpdateUser/UpdateUser";

import {UserProvider} from './assets/plugins/contexts/userContext';
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51JSk3pI9AXWBY8xCY76IifxyX6VNqVvLnCOYl8XNZ5xdXDSRCw4YPNfapCD7JiUFz4U1uzSu0mk3CoDQRN9ZbuDW00eoBCZ2yt');
render(
  <UserProvider>
  <Elements stripe={stripePromise}>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/admin" component={AdminLayout} />
        <PrivateRoute path="/update/:provider/:scope/:secretId" component={UpdateUser}/>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/admin/index" />
      </Switch>
    </BrowserRouter>
  </ThemeProvider>
  </Elements>
  </UserProvider>,
  document.querySelector("#root")
);

if(process.env.REACT_APP_VERSION === "Develpoment" || process.env.REACT_APP_VERSION === "Staging"){
  console.table(process.env);
}