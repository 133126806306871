import { updateUserGoogleAnalyticsSource } from "assets/plugins/firebase/db";
import axios from "axios";

export const googleAnalyticsLogin = () =>{
    const SCOPE = process.env.REACT_APP_GA_SCOPE;
    const GoogleAuth = window.gapi.auth2.getAuthInstance();

    return GoogleAuth.grantOfflineAccess({
        access_type: 'offline',
        prompt: 'consent',
        scope: SCOPE
    });

};

export const googleAnalyticsAPIAuth = googleAnalyticsResponse =>{
    return axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GOOGLE_API_URL}${googleAnalyticsResponse.code}`);
};

export const googleAnalyticsFirebaseUpdate = (user, data) =>{
    const today = new Date();
    const todayF = `${('0'+(today.getMonth() + 1)).slice(-2)}/${('0'+(today.getDate())).slice(-2)}/${today.getFullYear()}`;
    
    return updateUserGoogleAnalyticsSource({
        shared: data.shared,
        uid: user.uid,
        secretId: data.shared ? data.secretId : null,
        sharedSince: data.shared ? todayF : null
    });
};