// React
import { useEffect, useState } from 'react';

// Components
import Loading from 'components/Loading/Loading';

// Firebase
import { verifyActionCode } from 'assets/plugins/firebase/auth';

// Routing
import { useHistory } from 'react-router-dom';

// @material-ui
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Button, Card, CardContent, Grid, Snackbar, FormControl, FilledInput, InputAdornment } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Lock from "@material-ui/icons/Lock";
// theming
import componentStyles from "assets/theme/views/auth/login.js";

// Hooks
import useQuery from 'assets/plugins/hooks/useQuery';
import { confirmPasswordReset } from 'assets/plugins/firebase/auth';

const useStyles = makeStyles(componentStyles);

const EmailActionHandler = () => {

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const query = useQuery();

    const oobCode = query.get("oobCode");
    const mode = query.get("mode");

    const [LoadingState, setLoadingState] = useState(true);
    const [ErrorState, setErrorState] = useState({error:false, message:""});
    const [CodeErrorState, setCodeErrorState] = useState({error:false, message:""});

    useEffect( () => {
        const verifyResponse = async () => {
            try {
                await verifyActionCode(oobCode, mode);
                setLoadingState(false);
            } catch (error) {
                setLoadingState(false);
                setCodeErrorState({message:"The link have expired or has already been used, please try again.", error:true});
            };
        };

        verifyResponse();

    },[oobCode, mode]);

    const handleResetClick = async event => {
        event.preventDefault();
        try {
            await confirmPasswordReset(oobCode, event.target.password.value);
            history.push('/auth/passwordRecoverySuccessful');
        } catch (error) {
            setErrorState({message: error.message, error:true});
        };
    };

    if(LoadingState){return <Loading/>}

    return(
        <Grid item xs={12} lg={5} md={7}>
            <Card classes={{ root: classes.cardRoot }}>
                <CardContent classes={{ root: classes.cardContent }}>
                    {CodeErrorState.error ? 
                        <Box color={theme.palette.gray[800]} textAlign="center" marginBottom="1rem" marginTop=".5rem" fontSize="1rem">
                            <Box>{CodeErrorState.message}</Box>
                        </Box>
                    :
                    mode === "resetPassword" ?
                    <form onSubmit={handleResetClick}>
                        <Box color={theme.palette.gray[600]} textAlign="center" marginBottom="1rem" marginTop=".5rem" fontSize="1rem">
                            <Box >Reset your password</Box>
                        </Box>
                        <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important">
                            <FilledInput name="password" autoComplete="off" type="password" placeholder="New Password" startAdornment={<InputAdornment position="start"><Lock /></InputAdornment>}/>
                        </FormControl>
                        <Button variant="contained" type="submit">Change Password</Button>
                    </form>
                    :
                    <>
                        <Box color={theme.palette.gray[600]} textAlign="center" marginBottom="1rem" marginTop=".5rem" fontSize="1rem">
                            <Box fontSize="80%" fontWeight="400" component="small">Your email has been verified. You can now sign in with your new account</Box>
                        </Box>
                        <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                            <Button variant="contained" onClick={()=>history.push('/auth/login')}>Login</Button>
                        </Box>
                    </>
                    }
                </CardContent>
            </Card>
            <Snackbar open={ErrorState.error} autoHideDuration={10000} onClose={()=>setErrorState({error:false, message:""})}>
                <Alert onClose={()=>setErrorState({error:false, message:""})} severity="error">
                    {ErrorState.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default EmailActionHandler;