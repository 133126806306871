import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  "Production": {
    apiKey: "AIzaSyDdo8PSC9BNtglzCzxEMtKa8ALRHIQlUX4",
    authDomain: "churchfuel-317918.firebaseapp.com",
    projectId: "churchfuel-317918",
    storageBucket: "churchfuel-317918.appspot.com",
    messagingSenderId: "842441675137",
    appId: "1:842441675137:web:92b19234deb116f9759880"
  },
  "Staging": {
    apiKey: "AIzaSyDv9Wh1eZGYo2PmPLPHmvQNBKfpAA0ntsg",
    authDomain: "staging-birdseye.firebaseapp.com",
    projectId: "staging-birdseye",
    storageBucket: "staging-birdseye.appspot.com",
    messagingSenderId: "71053070057",
    appId: "1:71053070057:web:df64f55a6b271dabdc2a78"
  },
  "Demo": {
    apiKey: "AIzaSyCbCUQQBKgAvR2JHaY6YDTVrTiuZvb27Dc",
    authDomain: "fir-birdseye.firebaseapp.com",
    projectId: "fir-birdseye",
    storageBucket: "fir-birdseye.appspot.com",
    messagingSenderId: "311228926615",
    appId: "1:311228926615:web:a31fc864c8f1db6f6e77d2",
    measurementId: "G-2RCFG23MCF"
  }
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig[process.env.REACT_APP_VERSION]);

export const auth = firebase.auth();
export const sessionAuthPersistence = firebase.auth.Auth.Persistence.SESSION;
export const db = firebase.firestore(); 
export const storage = firebase.storage();